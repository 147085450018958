exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.container-kYDsd {\n  margin-bottom: 20px;\n  width: 100%;\n}\n\n.top-2kKjK {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 5px;\n}\n\n.title-2S70K {\n  font-size: 0.8rem;\n  font-weight: 600;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-column-gap: 6px;\n     -moz-column-gap: 6px;\n          column-gap: 6px;\n}\n\n.title-2S70K .info-2Z5Nn {\n    white-space: nowrap;\n  }\n\n.required-2HUzP {\n  color: #0a81ff;\n}\n\n.requiredMessage-3Qaui {\n  padding: 2px 10px;\n  background-color: #0a81ff;\n  text-transform: uppercase;\n  font-size: 9px;\n}\n\n.theme-dark .required-2HUzP {\n    color: #fff;\n  }\n\n.theme-dark .requiredMessage-3Qaui {\n    background-color: #0a81ff;\n  }\n\n.theme-light .required-2HUzP {\n    color: #212121;\n  }\n\n.theme-light .requiredMessage-3Qaui {\n    color: #fff;\n    background-color: #0a81ff;\n  }\n", ""]);

// exports
exports.locals = {
	"container": "container-kYDsd",
	"top": "top-2kKjK",
	"title": "title-2S70K",
	"info": "info-2Z5Nn",
	"required": "required-2HUzP",
	"requiredMessage": "requiredMessage-3Qaui"
};