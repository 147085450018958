/**
 * Возвращает путь к файлу
 *
 * @param {object} file объект DTO FileInfo
 */
export const makeFileUrl = (file) =>
  file?.storageGuid || file?.storageId ? `/api/rest/files/download/${file.storageGuid || file.storageId}` : undefined;

/**
 * Возвращает путь к изображению
 *
 * @param {object | null | undefined} file объект DTO FileInfo
 */
export const makeImageUrl = (file) => (file?.storageId ? `/api/rest/files/download/${file.storageId}` : undefined);

/**
 * Возвращает путь к изображению по storageId
 *
 * @param {string} storageId - uuid файла
 */
export const makeImageUrlFromStorageId = (storageId) => makeImageUrl({ storageId });

/**
 * Возвращает путь к пользовательскому соглашению
 *
 * @param {object} file объект DTO FileInfo
 */
export const makePersonalDataUrl = (file) => (file?.storageId ? `/api/rest/personalData/download/${file.storageId}` : undefined);

/**
 * Преобразует объект файла LinkFileView в объект FileInfo
 *
 * @param {object} file объект DTO LinkFileView
 */
export const makeInfoFromLink = (file) => {
  if (!file || typeof file !== 'object') {
    return undefined;
  }

  return {
    contentType: file.contentType,
    fileName: file.filename,
    id: file.storageId,
    size: file.size,
    storageId: file.storageGuid,
  };
};
