exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n/* react-flexbox-grid breakpoints*/\n\n.main-oy_sA {\n  padding: 80px 0;\n  max-width: 1100px;\n  margin: 0 auto;\n  min-height: calc(100vh - 140px);\n}\n\n.main-oy_sA.largeLayout-1ETf6 {\n    width: 100%;\n    max-width: 1300px;\n  }\n\n.container-bZHn9 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.navigation-DsQ2d {\n  display: none;\n  margin-right: 32px;\n}\n\n@media screen and (min-width: 992px) {\n\n.navigation-DsQ2d {\n    display: block;\n    width: calc((100% - 32px) / 12 * 2)\n}\n  }\n\n.content-2xr6y {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"main": "main-oy_sA",
	"largeLayout": "largeLayout-1ETf6",
	"container": "container-bZHn9",
	"navigation": "navigation-DsQ2d",
	"content": "content-2xr6y"
};