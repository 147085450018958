exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.fieldContainer-3Vut1 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.buttonContainer-1kxbW {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n}\n\n.listTitle-3P2yu {\n  font-weight: 600;\n  font-size: 0.8rem;\n  display: inline-block;\n  margin-bottom: 5px;\n}\n\n.trashButton-2KDuO {\n  margin-left: 10px;\n  -ms-flex-item-align: center;\n      align-self: center;\n}\n", ""]);

// exports
exports.locals = {
	"fieldContainer": "fieldContainer-3Vut1",
	"buttonContainer": "buttonContainer-1kxbW",
	"listTitle": "listTitle-3P2yu",
	"trashButton": "trashButton-2KDuO"
};