exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.link-3Slmn {\n  display: inline-block;\n  position: relative;\n}\n\n.link-3Slmn:not(:last-child) {\n    margin-right: 20px;\n  }\n\n.link-3Slmn:not(:last-child):after {\n      content: '';\n      height: 20px;\n      width: 1px;\n      background-color: #fff;\n      position: absolute;\n      right: -10px;\n    }\n\n.title-1AUTh {\n  text-transform: uppercase;\n  font-weight: 600;\n}\n\n.active-1BdFP {\n  color: #0a81ff;\n}\n\n.theme-dark .link-3Slmn:not(:last-child):after {\n        background-color: #fff;\n      }\n\n.theme-light .link-3Slmn:not(:last-child):after {\n        background-color: #b9b9b9;\n      }\n", ""]);

// exports
exports.locals = {
	"link": "link-3Slmn",
	"title": "title-1AUTh",
	"active": "active-1BdFP"
};