exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.mainContainer-3wuo7{\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.menu-3iSCA {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.linkContainer-2Wygv {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.navLink-1rBKw {\n  font-weight: 600;\n  margin: 0px 8px;\n  text-align: center;\n}\n\n.navLink-1rBKw.light-1hqsx {\n    color: #39395b;\n  }\n\n.navLink-1rBKw.active-Xzc-W {\n    color: #1fadff;\n    -webkit-text-decoration: revert;\n            text-decoration: revert;\n  }\n\n.navLink-1rBKw.disabled-193EA {\n    color: #9196af !important;\n    cursor: not-allowed;\n  }\n", ""]);

// exports
exports.locals = {
	"mainContainer": "mainContainer-3wuo7",
	"menu": "menu-3iSCA",
	"linkContainer": "linkContainer-2Wygv",
	"navLink": "navLink-1rBKw",
	"light": "light-1hqsx",
	"active": "active-Xzc-W",
	"disabled": "disabled-193EA"
};