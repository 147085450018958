import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Icon, Tooltip } from 'components/ui';
import { makeInfoFromLink } from 'utils/createUrl';
import { downloadFile } from 'utils/downloadFile';

import css from './FileLink.scss';

const FileLink = ({ fileInfo, fileLink, className, fileName, maxSizeOfName = 15, style }) => {
  const file = useMemo(() => fileInfo || makeInfoFromLink(fileLink), [fileInfo, fileLink]);
  const styles = useMemo(() => cn(css.file, className), [className]);

  const targetName = (fileName || file.fileName)?.toString() || 'неизвестное имя';
  const isShortened = targetName.length > maxSizeOfName;
  const visibleName = isShortened ? `${targetName.substring(0, maxSizeOfName - 3)}...` : targetName;

  return (
    <div className={styles} key={file.id} style={style}>
      <a className={css.container} onClick={() => downloadFile(file, fileName || file.fileName)}>
        <Icon icon="file" pack="fal" className={css.fileIcon} />
        {isShortened ? (
          <Tooltip className={css.fileTooltip} text={targetName}>
            {visibleName}
          </Tooltip>
        ) : (
          visibleName
        )}
      </a>
    </div>
  );
};

const PTFile = {
  contentType: PropTypes.string,
  fileName: PropTypes.string,
  id: PropTypes.number,
  storageId: PropTypes.string,
};

FileLink.propTypes = {
  className: PropTypes.string,
  fileInfo: PropTypes.shape(PTFile),
  fileLink: PropTypes.object,
  fileName: PropTypes.node,
  maxSizeOfName: PropTypes.number,
};

FileLink.defaultProps = {
  className: undefined,
  fileInfo: undefined,
  fileLink: undefined,
  fileName: undefined,
  style: undefined,
  maxSizeOfName: 15,
};

export default React.memo(FileLink);
