import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faEllipsisV as falEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faChevronUp as falChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faEnvelopeOpen as falEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faTrash as falTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faLockAlt as falLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faArrowsH as falArrowsH } from '@fortawesome/pro-light-svg-icons/faArrowsH';
import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faHourglassHalf as falHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { faUsdCircle as falUsdCircle } from '@fortawesome/pro-light-svg-icons/faUsdCircle';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faBan as falBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faMinusCircle as falMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faPen as falPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCompressAlt as falCompressAlt } from '@fortawesome/pro-light-svg-icons/faCompressAlt';
import { faClone as falClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faExternalLink as falExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faRepeat as falRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat';
import { faRedo as falRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faHistory as falHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faPauseCircle as falPauseCircle } from '@fortawesome/pro-light-svg-icons/faPauseCircle';
import { faStopwatch as falStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faPause as falPause } from '@fortawesome/pro-light-svg-icons/faPause';
import { faAt as falAt } from '@fortawesome/pro-light-svg-icons/faAt';
import { faFileDownload as falFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faClock as falClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faAngleUp as falAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faFile as falFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faFileUpload as falFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faAngleLeft as falAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faFileWord as falFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import { faFilePdf as falFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileImage as falFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage';

import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faAt as fasAt } from '@fortawesome/pro-solid-svg-icons/faAt';
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faSpinnerThird as fasSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { faExclamation as fasExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';

import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faEllipsisV as farEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faEnvelopeOpen as farEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen';
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faArrowsH as farArrowsH } from '@fortawesome/pro-regular-svg-icons/faArrowsH';
import { faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faHourglassHalf as farHourglassHalf } from '@fortawesome/pro-regular-svg-icons/faHourglassHalf';
import { faUsdCircle as farUsdCircle } from '@fortawesome/pro-regular-svg-icons/faUsdCircle';
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faBan as farBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faMinusCircle as farMinusCircle } from '@fortawesome/pro-regular-svg-icons/faMinusCircle';
import { faPlusCircle as farPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faCompressAlt as farCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faClone as farClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faRepeat as farRepeat } from '@fortawesome/pro-regular-svg-icons/faRepeat';
import { faRedo as farRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faHistory as farHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faPauseCircle as farPauseCircle } from '@fortawesome/pro-regular-svg-icons/faPauseCircle';
import { faStopwatch as farStopwatch } from '@fortawesome/pro-regular-svg-icons/faStopwatch';
import { faPause as farPause } from '@fortawesome/pro-regular-svg-icons/faPause';
import { faAt as farAt } from '@fortawesome/pro-regular-svg-icons/faAt';
import { faFileDownload as farFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faAngleUp as farAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faFileUpload as farFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faFileWord as farFileWord } from '@fortawesome/pro-regular-svg-icons/faFileWord';
import { faFilePdf as farFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileImage as farFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar';

import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faImage as farImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faAngleDoubleUp as farAngleDoubleUp } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleUp';
import { faCalendarCheck as farCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import { faHome as farHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faPaperPlane as farPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faSortAmountUp as farSortAmountUp } from '@fortawesome/pro-regular-svg-icons/faSortAmountUp';
import { faSortAmountDown as farSortAmountDown } from '@fortawesome/pro-regular-svg-icons/faSortAmountDown';
import { faUpload as farUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faDownload as farDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faSignOut as farSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faSignIn as farSignIn } from '@fortawesome/pro-regular-svg-icons/faSignIn';
import { faExpand as farExpand } from '@fortawesome/pro-regular-svg-icons/faExpand';
import { faCompress as farCompress } from '@fortawesome/pro-regular-svg-icons/faCompress';
import { faCamera as farCamera } from '@fortawesome/pro-regular-svg-icons/faCamera';
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faPercent as farPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faExclamation as farExclamation } from '@fortawesome/pro-regular-svg-icons/faExclamation';
import { faSpinnerThird as farSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faFutbol as farFutbol } from '@fortawesome/pro-regular-svg-icons/faFutbol';
import { faFileTimes as farFileTimes } from '@fortawesome/pro-regular-svg-icons/faFileTimes';
import { faFileCheck as farFileCheck } from '@fortawesome/pro-regular-svg-icons/faFileCheck';
import { faRandom as farRandom } from '@fortawesome/pro-regular-svg-icons/faRandom';
import { faUserTimes as farUserTimes } from '@fortawesome/pro-regular-svg-icons/faUserTimes';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faUserPlus as farUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faStopCircle as farStopCircle } from '@fortawesome/pro-regular-svg-icons/faStopCircle';
import { faPlayCircle as farPlayCircle } from '@fortawesome/pro-regular-svg-icons/faPlayCircle';
import { faCamera as fasCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faFolder as farFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faArrowCircleUp as farArrowCircleUp } from '@fortawesome/pro-regular-svg-icons/faArrowCircleUp';
import { faArrowCircleDown as farArrowCircleDown } from '@fortawesome/pro-regular-svg-icons/faArrowCircleDown';
import { faArrowToBottom as farArrowToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom';
import { faSave as farSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faSync as farSync } from '@fortawesome/pro-regular-svg-icons/faSync';

const customStarIcon = {
  prefix: 'far',
  iconName: 'star',
  icon: [20, 19, [], null, 'M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z'],
};

const customStarUnfilledIcon = {
  prefix: 'far',
  iconName: 'star-unfilled',
  icon: [
    67,
    63,
    [],
    null,
    `M33,10.822l5.797,11.746l1.163,2.357l2.602,0.378l12.963,1.884l-9.38,9.143l-1.882,1.835l0.444,2.591
      l2.214,12.91L35.327,47.57L33,46.348l-2.327,1.223l-11.594,6.096l2.214-12.91l0.444-2.591l-1.882-1.835l-9.38-9.143l12.962-1.884
        l2.602-0.378l1.163-2.357L33,10.822 M33-0.475L22.719,20.355L-0.27,23.696L16.365,39.91l-3.927,22.896L33,51.996l20.562,10.811
          L49.635,39.91L66.27,23.696L43.28,20.355L33-0.475L33-0.475z`,
  ],
};

export const faIconsLib = [
  farSync,
  fasCheckCircle,
  fasCog,
  fasInfoCircle,
  fasCheck,
  fasSpinnerThird,
  fasExclamation,
  fasAt,
  fasLockAlt,
  fasSearch,
  falCheck,
  falFilter,
  falEllipsisV,
  falAngleRight,
  falChevronUp,
  falChevronDown,
  falEnvelopeOpen,
  falEnvelope,
  falTrash,
  falLockAlt,
  falInfoCircle,
  falArrowsH,
  falFileAlt,
  falHourglassHalf,
  falUsdCircle,
  falTimes,
  falBan,
  falMinusCircle,
  falPlusCircle,
  falPen,
  falCompressAlt,
  falClone,
  falExternalLink,
  falRepeat,
  falRedo,
  falHistory,
  falPauseCircle,
  falStopwatch,
  falPause,
  falAt,
  falFileDownload,
  falClock,
  falAngleUp,
  falFile,
  falAngleDown,
  falExclamationCircle,
  falFileUpload,
  falAngleLeft,
  falCalendarAlt,
  falFileWord,
  falFilePdf,
  falFileImage,
  farCheck,
  farFilter,
  farEllipsisV,
  farAngleRight,
  farChevronUp,
  farChevronDown,
  farEnvelopeOpen,
  farEnvelope,
  farTrash,
  farLockAlt,
  farInfoCircle,
  farArrowsH,
  farFileAlt,
  farHourglassHalf,
  farUsdCircle,
  farTimes,
  farBan,
  farMinusCircle,
  farPlusCircle,
  farPen,
  farCompressAlt,
  farClone,
  farExternalLink,
  farRepeat,
  farRedo,
  farHistory,
  farPauseCircle,
  farStopwatch,
  farPause,
  farAt,
  farFileDownload,
  farClock,
  farAngleUp,
  farFile,
  farAngleDown,
  farExclamationCircle,
  farFileUpload,
  farAngleLeft,
  farCalendarAlt,
  farFileWord,
  farFilePdf,
  farFileImage,
  farUser,
  farEye,
  farPaperclip,
  farSearch,
  farImage,
  farAngleDoubleUp,
  farCalendarCheck,
  farHome,
  farQuestionCircle,
  farPaperPlane,
  farSortAmountUp,
  farSortAmountDown,
  farUpload,
  farDownload,
  farSignOut,
  farSignIn,
  farExpand,
  farCompress,
  farCamera,
  farPlus,
  farPercent,
  farExclamation,
  farSpinnerThird,
  farTrashAlt,
  fasStar,
  farStar,
  farFutbol,
  farFileTimes,
  farFileCheck,
  farRandom,
  farUserTimes,
  fasExclamationTriangle,
  farUserPlus,
  farStopCircle,
  farPlayCircle,
  fasCamera,
  farFolder,
  farArrowCircleUp,
  farArrowCircleDown,
  farArrowToBottom,
  farSave,
  farCheckCircle,
  customStarIcon,
  customStarUnfilledIcon,
];
