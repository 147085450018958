exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.wrapper-frea_ {\n  position: relative;\n  margin-bottom: 80px\n}\n\n.tabContainer-XQaAN {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.tabContainer-XQaAN:after {\n    position: absolute;\n    bottom: 1px;\n    /* z-index: -1;*/\n    display: block;\n    width: 100%;\n    height: 1px;\n    background-color: rgba(255, 255, 255, 0.3);\n    content: '';\n  }\n\n.underline-2cvX3 {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 0;\n  height: 3px;\n  background-color: #0a81ff;\n  -webkit-transition: 0.3s ease-out;\n  transition: 0.3s ease-out;\n}\n\n.theme-dark .tabContainer-XQaAN:after {\n      background-color: rgba(255, 255, 255, 0.3);\n    }\n\n.theme-dark .underline-2cvX3 {\n    background-color: #0a81ff;\n  }\n\n.theme-light .tabContainer-XQaAN:after {\n      background-color: #bababa;\n    }\n\n.theme-light .underline-2cvX3 {\n    background-color: #0070b6;\n  }\n", ""]);

// exports
exports.locals = {
	"wrapper": "wrapper-frea_",
	"tabContainer": "tabContainer-XQaAN",
	"underline": "underline-2cvX3"
};