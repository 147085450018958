exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.disclaimer-1s7i2 {\n  position: fixed;\n  z-index: 1000;\n  background-color: #b4bfd5;\n  width: 100%;\n  color: #565e85;\n  font-size: 16px;\n  text-align: center;\n}\n\n.content-gs_Q1 {\n  padding: 20px 0;\n  max-width: 1800px;\n  margin: 0 auto;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.content-gs_Q1 a, .content-gs_Q1 .link--37kk {\n    color: #373865;\n    cursor: pointer;\n  }\n\n.content-gs_Q1 h4 {\n    margin: 0 0 15px;\n  }\n\n.content-gs_Q1 p {\n    margin: 10px 0;\n  }\n\n.item-1d5Pb {\n  padding: 0 20px;\n  position: relative;\n}\n\n.button-3CMQC {\n  width: 170px;\n  margin-top: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"disclaimer": "disclaimer-1s7i2",
	"content": "content-gs_Q1",
	"link": "link--37kk",
	"item": "item-1d5Pb",
	"button": "button-3CMQC"
};