exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.tab-3QthH {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin: 0;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin-right: 40px;\n  padding: 0;\n  height: 60px;\n  border: none;\n  background-color: transparent;\n  color: #fff;\n  font-weight: 600;\n\n  cursor: pointer;\n  -webkit-transition: 0.3s;\n  transition: 0.3s;\n}\n\n.tab-3QthH:focus {\n    outline: none;\n  }\n\n.tab-3QthH:last-child {\n    margin-right: 0;\n  }\n\n.active-33_GP {\n  color: #ffffff;\n}\n\n.disabled-3Yt2E {\n  color: rgba(255, 255, 255, 0.5);\n  cursor: not-allowed;\n}\n\n.theme-dark .tab-3QthH {\n    color: #ffffff;\n  }\n\n.theme-dark .active-33_GP {\n    color: #ffffff;\n  }\n\n.theme-light .tab-3QthH {\n    color: #212121;\n  }\n\n.theme-light .active-33_GP {\n    color: #1d94c1;\n  }\n", ""]);

// exports
exports.locals = {
	"tab": "tab-3QthH",
	"active": "active-33_GP",
	"disabled": "disabled-3Yt2E"
};