exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------\nCommon\n--------------------------*/\n\n/* layouts width*/\n\n/* ------------------------\nDark Theme\n--------------------------*/\n\n/* colors*/\n\n/* input*/\n\n/* modal content padding*/\n\n/* ------------------------\nLight Theme\n--------------------------*/\n\n/* colors*/\n\n/* modal content padding*/\n\n/* ------------------------\nRating\n--------------------------*/\n\n.container-24Iwo {\n  position: absolute;\n  top: 40%;\n  left: 0;\n  right: 0;\n  padding: 20px;\n  width: 320px;\n  cursor: default;\n  background-color: #fff;\n\n  margin: 0 auto;\n  z-index: 1000;\n  border: none;\n  border-radius: 8px;\n}\n\n.header-3DxRg {\n  font-family: 'OpenSans', sans-serif;\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1.43;\n  letter-spacing: 0.6px;\n  color: #001a5d;\n  margin: 0 0 32px;\n}\n\n.buttons-3Judm {\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n}\n\n.button-2z24z {\n  font-family: 'OpenSans', sans-serif;\n  letter-spacing: .6px;\n  font-size: 14px;\n  font-weight: 600;\n  height: 20px;\n  line-height: 20px;\n  padding: 0 10px;\n}\n\n.button-2z24z:not(:last-child) {\n    margin-right: 20px;\n  }\n\n.button-2z24z:hover {\n    opacity: .6;\n  }\n\n.buttonYes-25-7x, .buttonYes-25-7x:hover {\n    color: #001a5d;\n  }\n\n.buttonNo-_Ar2z, .buttonNo-_Ar2z:hover {\n    color: #eb0a26;\n  }\n\n.theme-dark .container-24Iwo, .theme-light .container-24Iwo {\n    background-color: #fff;\n  }\n\n.theme-dark .buttonYes-25-7x, .theme-dark .buttonYes-25-7x:hover, .theme-light .buttonYes-25-7x, .theme-light .buttonYes-25-7x:hover {\n      color: #001a5d;\n    }\n\n.theme-dark .buttonNo-_Ar2z, .theme-dark .buttonNo-_Ar2z:hover, .theme-light .buttonNo-_Ar2z, .theme-light .buttonNo-_Ar2z:hover {\n      color: #eb0a26;\n    }\n", ""]);

// exports
exports.locals = {
	"container": "container-24Iwo",
	"header": "header-3DxRg",
	"buttons": "buttons-3Judm",
	"button": "button-2z24z",
	"buttonYes": "buttonYes-25-7x",
	"buttonNo": "buttonNo-_Ar2z"
};