import axios from 'axios';
import Notification from 'components/rfs-ui/Notification/Notification';
import { handleError } from 'magic/handlers';
import { makeFileUrl } from './createUrl';

const _handleError = (message, toastId) =>
  toastId
    ? Notification.updateToast(toastId, {
        message,
        status: 'error',
        autoClose: 5000,
      })
    : handleError(message);

/**
 * @param {object} error объект ошибки на вход, из axios или обычный
 * @param {string} [toastId] ид елемента уведомления
 */
export const handleErrorFromBlob = (error, toastId) => {
  if (error.response?.data) {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const errorMessage = reader.result ? JSON.parse(reader.result).synopsis : 'Произошла ошибка при загрузке файла';
        _handleError(errorMessage, toastId);
      } catch {
        _handleError('Ошибка при чтении данных ответа', toastId);
      }
    };

    reader.onerror = () => {
      // eslint-disable-next-line no-console
      console.error(reader.error);
      _handleError('Ошибка при чтении данных ответа', toastId);
    };

    reader.readAsText(error.response.data);
  } else {
    _handleError('Произошла ошибка при загрузке файла', toastId);
  }
};

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

const getBlobFromFileData = (fileData) => new Blob([fileData instanceof Uint8Array ? fileData : base64ToArrayBuffer(fileData)]);

/**
 * @param fileData Blob или ArrayBuffer
 * @param {string} fileName имя файла
 * @param {string} [toastId] ид елемента уведомления
 */
function openSaveDialog(fileData, fileName, toastId) {
  if ((!fileData && fileData !== '') || !fileName) return;

  const blob = fileData instanceof Blob ? fileData : getBlobFromFileData(fileData);

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  link.remove();

  URL.revokeObjectURL(url);

  if (toastId) {
    Notification.updateToast(toastId, {
      message: `Загрузка ${fileName ? `«${fileName}»` : ''} завершена!`,
      status: 'success',
      autoClose: 2000,
    });
  }
}

/**
 * @param {string} url url запроса
 * @param {string} [fileName] имя файла
 */
export const downloadFileByLink = async (url, fileName) => {
  const toastId = Notification.info({
    children: { message: `Загрузка ${fileName ? `«${fileName}»` : ''} началась...`, isLoading: true },
    params: { autoClose: 30000 },
  });

  let _fileName = fileName;

  try {
    const response = await axios.get(url, { responseType: 'blob' });

    if (!fileName) {
      const contentDisposition = response.headers?.['content-disposition'];

      if (contentDisposition) {
        const matches = /filename\*?=(?:UTF-8'')?([^;\n]*)/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          _fileName = decodeURIComponent(matches[1].replace(/['"]/g, '').trim());
        }
      }
    }

    openSaveDialog(response.data, _fileName || 'unnamed_file', toastId);
  } catch (error) {
    handleErrorFromBlob(error, toastId);
  }
};

/**
 * @param {object} file объект DTO FileInfo
 * @param {string} [fileName] имя файла
 */
export const downloadFile = (file, fileName) => {
  if (!file.storageGuid && !file.storageId) {
    Notification.warning({ children: { message: 'Отсутствует ссылка на файл' } });
    return Promise.resolve();
  }

  return downloadFileByLink(makeFileUrl(file), fileName);
};
