import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css?external';
import cn from 'classnames';
import Icon from 'components/ui/Icon/Icon';
import * as ReactDOM from 'react-dom';
import { Spinner } from './Spinner';
import styles from './Notification.scss';

/**
 * Рендерит контент нотификашки
 *
 * @param {string} icon - иконка
 * @param {string} message - сообщение
 * @param {boolean} isLoading - загрузка
 */
export const ToastElement = (icon, message, isLoading) => (
  <div className={styles.toastInner}>
    {icon && (
      <div className={styles.iconContainer}>
        <Icon icon={icon} color="#fff" size="3x" className={styles.icon} />
      </div>
    )}
    <div className={styles.message}>
      {message} {isLoading && <Spinner />}
    </div>
  </div>
);

/**
 * Created using react-toastify (https://github.com/fkhadra/react-toastify)
 */
class Notification extends React.Component {
  static toasts = [];
  /**
   * Создает дом-узел и рендерит туда контейнер для нотификашек
   */
  static init() {
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
    ReactDOM.render(
      <ToastContainer className={styles.mainContainer} toastClassName={styles.toastWrapper} position="top-left" hideProgressBar />,
      this.root,
    );
  }

  /**
   * Добавляет id нотификашки в массив
   *
   * @param {number} toastId - id нотификашки
   */
  static addToast(toastId) {
    this.toasts.push(toastId);
  }

  /**
   * Удаляет id нотификашки из массива, а также удаляет весь контейнер, если их там больше нет
   *
   * @param {number} toastId - id нотификашки
   */
  static dropToast(toastId) {
    const toastIdx = this.toasts.indexOf(toastId);
    if (toastIdx >= 0) {
      this.toasts.splice(toastIdx, 1);
    }

    if (!this.toasts.length && this.root) {
      document.body.removeChild(this.root);
      this.root = null;
    }
  }

  /**
   * Инициализирует дом-узел для контейнера нотификаций, если нужно, и создает нотификацию
   *
   * @param {string} style - стиль нотификашки
   * @param {object} toastConfig - параметры для нотификашки, включая контент
   */
  static renderToast(style, toastConfig) {
    if (!this.root) {
      this.init();
    }

    let toastElement;

    // если передали функцию, которая рисует компонент, то выводим его
    if (typeof toastConfig.children === 'function') {
      toastElement = toastConfig.children;
    } else {
      // иначе дефолтный компонент
      const { icon = 'exclamation', message, isLoading } = toastConfig.children;
      toastElement = ToastElement(icon, message, isLoading);
    }

    const newToast = toast(toastElement, {
      bodyClassName: cn(styles.toastBody, style),
      onOpen: () => this.addToast(newToast),
      onClose: () => this.dropToast(newToast),
      closeButton: false,
      ...toastConfig.params,
    });

    return newToast;
  }

  static updateToast(toastId, toastConfig) {
    const { icon = 'exclamation', message, isLoading, status, ...rest } = toastConfig;

    toast.update(toastId, {
      render: ToastElement(icon, message, isLoading),
      bodyClassName: cn(styles.toastBody, styles[status]),
      ...rest,
    });
  }

  /**
   * Нотификация об успехе
   *
   * @param {object} toastConfig - параметры для нотификашки, включая контент
   */
  static success(toastConfig) {
    return this.renderToast(styles.success, toastConfig);
  }

  /**
   * Нотификация об ошибке
   *
   * @param {object} toastConfig - параметры для нотификашки, включая контент
   */
  static error(toastConfig) {
    return this.renderToast(styles.error, toastConfig);
  }

  /**
   * Предупреждающая нотификация
   *
   * @param {object} toastConfig - параметры для нотификашки, включая контент
   */
  static warning(toastConfig) {
    return this.renderToast(styles.warning, toastConfig);
  }

  /**
   * Информационная нотификация
   *
   * @param {object} toastConfig - параметры для нотификашки, включая контент
   */
  static info(toastConfig) {
    return this.renderToast(styles.info, toastConfig);
  }
}

export default Notification;
