/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import { Notification } from 'components/rfs-ui';
import { Input, Button, Icon, Preloader } from 'components/ui';
import { downloadFile } from 'utils/downloadFile';
import { uploadFile, uploadFiles } from 'utils/uploadFiles';

import css from './FileUploader.scss';

const DEFAULT_MAXSIZE = 10485760;

class FileUploader extends React.Component {
  static propTypes = {
    localFile: PropTypes.bool,
    accept: PropTypes.arrayOf(PropTypes.string),
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    data: PropTypes.object,
    customUploadButton: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func,
    altFetchUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    type: PropTypes.string,
    buttonText: PropTypes.string,
  };

  static defaultProps = {
    maxSize: DEFAULT_MAXSIZE,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: props.data ? props.data : [],
      isLoading: false,
    };
  }

  getIconByType = (type) => {
    switch (type) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'file-word';
      case 'application/pdf':
        return 'file-pdf';
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return 'file-image';
      default:
        return 'file-alt';
    }
  };

  handleFileUpload = async (e) => {
    const { accept, maxSize, onChange, altFetchUrl, uploadOnChange, uploadOnChangeAll } = this.props;
    const { files } = e.target;
    const filteredFiles = [];
    const allFiles = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if (accept && !accept.includes(file.type)) {
        Notification.error({
          children: {
            message: `Файл ${file.name} имеет недопустимое расширение и не может быть загружен`,
          },
        });

        continue;
      }

      if (!file.size) {
        Notification.error({
          children: {
            message: `Файл ${file.name} пуст`,
          },
        });

        continue;
      }

      if (maxSize && file.size > maxSize) {
        Notification.error({
          children: {
            message: `Размер файла ${file.name} превышает допустимый`,
          },
        });

        continue;
      }

      if (uploadOnChange) {
        onChange(file);
      } else if (uploadOnChangeAll) {
        allFiles.push(file);
      } else {
        // вызов утилиты
        const result = uploadFile(file, altFetchUrl);
        filteredFiles.push(result);
      }
    }
    if (allFiles.length) {
      onChange(allFiles);
    }
    if (filteredFiles.length) {
      this.setState({ isLoading: true });
      const _newFiles = await uploadFiles(filteredFiles);
      const uploadNewFiles = [...this.state.files, ..._newFiles];
      if (onChange) {
        onChange(uploadNewFiles);
      }
      this.setState({ files: uploadNewFiles, isLoading: false });
      //     })
      //     .catch(() => this.setState({ files: this.state.files, isLoading: false }));
    }
  };

  openFileBrowse = () => {
    this.input.value = null; // нужно, чтобы можно было перезалить один и тот же файл
    this.input.click();
  };

  clearInput = (idx) => {
    const { onChange, multiple } = this.props;
    const files = this.state.files.filter((file, index) => idx !== index);

    if (onChange) {
      onChange(multiple ? files : {});
    }
    this.setState({ files });
  };

  render() {
    const { data, localFile = true } = this.props;
    const { isLoading, files: localFiles } = this.state;
    const { accept, multiple, customUploadButton, label, className, onChange, type, buttonText } = this.props;

    const files = localFile ? localFiles : data || [];

    if (isLoading) {
      return <Preloader pack="far" className={cn(css.filePreloader, className)} />;
    }

    const showAddButton = onChange && (multiple || !files.length);

    return (
      <div className={cn(css.container, className)}>
        {!isEmpty(files) && (
          <div className={css.filesContainer}>
            {files.map((file, idx) => (
              <div className={css.fileContainer} key={uniqueId('fileItem_')}>
                <div className={css.file}>
                  <Icon icon={this.getIconByType(file.contentType)} size="1x" pack="fal" className={css.extension} />
                  <a onClick={() => downloadFile(file, file.fileName)} className={css.filename}>
                    {file.fileName}
                  </a>
                </div>
                <div className={css.nameBlock}>
                  <span>{file.fileName}</span>
                </div>
                {onChange && <Icon icon="times" size="1x" pack="fal" className={css.removeFile} onClick={() => this.clearInput(idx)} />}
              </div>
            ))}
          </div>
        )}
        {showAddButton &&
          (customUploadButton ? (
            <div className={cn(css.customUploadButton)}>
              {React.cloneElement(customUploadButton, { onClick: this.openFileBrowse })}
              {label && <span className={css.uploadText}>{label}</span>}
            </div>
          ) : (
            <div>
              {type === 'string' ? (
                <div onClick={this.openFileBrowse} className={css.stringButton}>
                  <Icon icon="plus-circle" />
                  {buttonText}
                </div>
              ) : (
                <Button type="secondary" className={css.uploadButton} onClick={this.openFileBrowse}>
                  Выберите файл
                </Button>
              )}
              {label && <span className={css.uploadText}>{label}</span>}
            </div>
          ))}
        <Input
          type="file"
          onChange={this.handleFileUpload}
          className={css.inputUploader}
          refer={(input) => {
            this.input = input;
          }}
          multiple={multiple}
          accept={accept.join(', ')}
        />
      </div>
    );
  }
}

export default FileUploader;
